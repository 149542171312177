import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import List from "Components/List/List";
import FlexRow from "Components/FlexRow";
import DotsIcon from "Assets/SVG/DotsIcon";
import { IPrintDto } from "Apis/Models/IPrintDto";
import CopyIcon from "../../../Assets/SVG/CopyIcon";
import {usePrintType} from "../Hooks/UsePrintType";
import {formatNumber} from "../../../Utils/NumberUtils";

interface IProps {
    prints?: IPrintDto[];
    isLoading: boolean;
}
const PrintsList = (props: IProps) => {
    
    const { t } = useTranslation();

    const { getPrintTypeLabel } = usePrintType()
    
    const { prints, isLoading } = props;
    
    const navigate = useNavigate();
    
    return (
        <List<IPrintDto> data={prints} loading={isLoading} columns={[
            {
                title: t('name'),
                textRender: (record) => record.name
            },
            {
                title: t('project'),
                textRender: (record) => record.project.name
            },
            {
                title: t('printType.printType'),
                textRender: (record) => getPrintTypeLabel(record.printType)
            },
            {
                title: t('printPlacement'),
                textRender: (record) => record.printPlacement.name
            },
            {
                title: t('printSize'),
                textRender: (record) => record.printSize?.name ?? record.customSize,
            },
            {
                title: t('printColor'),
                textRender: (record) => record.color,
            },
            {
                title: t('otherDetails'),
                textRender: (record) => [record.foilType?.name, record.printingType?.name, record.pretreatment, record.transferType?.name, record.vendor].filter(Boolean).join(", "),
            },
            {
                title: t('unitCostPrice'),
                textRender: (record) => `${formatNumber(record.unitCostPrice)} DKK`,
                size: 'auto'
            },
            {
                title: t('unitSalesPrice'),
                textRender: (record) => `${formatNumber(record.unitSalesPrice)} DKK`,
                size: 'auto'
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => (
                    <FlexRow className={"h-100 clickable"} justify={"center"} style={{gap: 10}}>
                        <FlexRow className={"clickable"} justify={"center"} align={"center"} onClick={() => navigate(`/prints/clone/${record.id}`)} style={{paddingRight:15}}>
                            <CopyIcon width={30} height={30} />
                        </FlexRow>
                        <FlexRow className={"clickable"} justify={"center"} align={"center"} onClick={() => navigate(`/prints/edit/${record.id}`)}>
                            <DotsIcon />
                        </FlexRow>
                    </FlexRow>
                )
            }
        ]} keyExtractor={(record) => record.id.toString()} imageExtractor={(record => [record.mockUpFile!])} />
    )
}

export default PrintsList
