import {IDesignDto, IMutateDesignDto} from "../../../Apis/Models/IDesignDto";
import {useFieldArray, useForm} from "react-hook-form";
import styles from "../../Products/Components/ProductForm.module.scss";
import XIcon from "../../../Assets/SVG/XIcon";
import ControlledSelect from "../../../Components/Select/ControlledSelect";
import Select from "../../../Components/Select";
import {usePrints} from "../../../Apis/PrintApi";
import useFilteredList from "../../../Hooks/UseFilteredList";
import React, {useEffect} from "react";
import FlexRow from "../../../Components/FlexRow";
import Button from "../../../Components/Button";
import Space from "../../../Components/Space";
import ControlledInput from "../../../Components/Input";
import {useProjects} from "../../../Apis/ProjectApi";
import { useTranslation } from "react-i18next";
import {useMutateDesign} from "../../../Apis/DesignApi";
import {usePrintType} from "../../Prints/Hooks/UsePrintType";
import Label from "../../../Components/Label";
import {forEach} from "lodash";

interface IProps {
    design?: Partial<IDesignDto>;
    printId?: string | undefined;
    onSubmit?: (data: IMutateDesignDto) => void;
}

const DesignForm = (props: IProps) => {

    const { t } = useTranslation();

    const {design, onSubmit, printId} = props;

    const { mutateAsync, isLoading } = useMutateDesign();

    const { getPrintTypeLabel } = usePrintType();

    const { control, handleSubmit, reset, watch, setValue } = useForm<IMutateDesignDto>();
    const { fields, append, remove } = useFieldArray({
        control,
        name: "prints",
        keyName: 'fieldId'
    });

    const selectedProject = watch('projectId');

    const { data: prints } = usePrints({includeAll: true, projectId: selectedProject});
    const { data: projects } = useProjects({ includeAll: true});

    const [ filteredProjects, setProjectsFilter ] = useFilteredList(projects?.items ?? [], x => [x.name]);
    const [ filteredPrints, setPrintFilter ] = useFilteredList(prints?.items ?? [], x => [x.name]);

    useEffect(() => {
        if (design) {
            reset(design);
        }

        if (printId && !design && prints) {
            const print = prints?.items?.find(x => x.id === parseInt(printId));

            reset({
                name: print?.name,
                projectId: print?.projectId,
                prints: [{id: print?.id}]
            })
        }
    }, [reset, design, printId, prints])

    const save = async(data: IMutateDesignDto) => {
        await mutateAsync(data);

        if (onSubmit) {
            onSubmit(data);
        }

        reset();
    }

    const getMockupFile = (printId: number | undefined) => {
        return prints?.items?.find(x => x.id === printId)?.mockUpFile;
    }

    const generateDesignName = () => {
        let printIds = watch('prints').map(x => x.id);

        let foundPrints = prints?.items?.filter(x => printIds.includes(x.id));

        let nameParts: string[] = [];

        forEach(foundPrints ?? [], (print) => {
            let parts: string[] = [];

            switch (print.printType) {
                case "DTG":
                    parts.push(print.pretreatment ?? "");
                    break;
                case "Transfer":
                    parts.push(print.transferType?.name ?? "");
                    parts.push(print.color ?? "");
                    parts.push(print.customSize ?? "");
                    break;
                case "Plot":
                    parts.push("Plot");
                    parts.push(print.foilType?.name ?? "");
                    parts.push(print.color ?? "");
                    parts.push(print.customSize ?? "");
                    break;
                case "DTF":
                    parts.push("DTF");
                    parts.push(print.color ?? "");
                    parts.push(print.customSize ?? "");
                    break;
                case "Printing":
                    parts.push(print.printingType?.name ?? "");
                    parts.push(print.color ?? "");
                    parts.push(print.customSize ?? "");
                    break;
                case "Embroidery":
                    parts.push("Brodering");
                    parts.push(print.color ?? "");
                    parts.push(print.customSize ?? "");
                    break;
            }

            parts.push(print.printPlacement?.name ?? "");

            nameParts.push(parts.filter(x => !!x).join(' '));
        })

        let currentName = watch('name') ?? "";

        const firstBracket = currentName.indexOf("[");
        const lastBracket = currentName.lastIndexOf("]");
        
        if (firstBracket >= 0 && lastBracket >= 0) {
            currentName = currentName.substring(firstBracket + 1, lastBracket);
        }

        currentName = `${currentName} [${nameParts.filter(x => !!x).join(', ')}]`

        setValue('name', currentName);
    }

    return (
        <form onSubmit={handleSubmit(save)}>
            <Space direction={"vertical"} size={"large"}>

                <ControlledInput rules={{required: true}} name={"name"} control={control} placeholder={t('name')} label={(
                    <div>
                        <Label placeholder={"name"} /> - <span style={{fontSize: 12, cursor: 'pointer'}} onClick={generateDesignName}>{t('generateProductName')}</span>
                    </div>
                )} />

                <ControlledSelect rules={{required: true}} onFilter={setProjectsFilter} control={control} placeholder={t('project')} name={"projectId"}>
                    {filteredProjects.map(project => <Select.Option key={project.id} value={project.id} label={project.name} />)}
                </ControlledSelect>

                {fields.map((field, index) => (
                    <div key={field.fieldId} className={styles.printsContainer}>
                        <div className={styles.delete} onClick={() => remove(index)}>
                            <XIcon />
                        </div>
                        <img height={100} width={100} src={getMockupFile(watch(`prints.${index}.id`))?.url} alt={getMockupFile(watch(`prints.${index}.id`))?.fileName} />
                        <div>
                            <ControlledSelect rules={{required: true}} control={control} placeholder={t('print')} name={`prints.${index}.id`} onFilter={setPrintFilter} >
                                {filteredPrints?.map(print => <Select.Option key={print.id} label={`${print.name} - ${print.printPlacement.name} - ${getPrintTypeLabel(print.printType)}`} value={print.id} />)}
                            </ControlledSelect>
                        </div>
                    </div>
                ))}

                <FlexRow justify={"end"}>
                    <Button buttonType={"button"} type={"tertiary"} text={t('addPrint')} onClick={() => append({id: 0})} />
                </FlexRow>

                <FlexRow justify={"end"}>
                    <Button buttonType={"submit"} type={"primary"} text={t('save')} loading={isLoading} />
                </FlexRow>
            </Space>
        </form>
    )

}

export default DesignForm