import Button from "Components/Button";
import FlexRow from "Components/FlexRow";
import Modal from "Components/Modal";
import Space from "Components/Space";
import {Trans, useTranslation} from "react-i18next";
import {useCheckIfOrdersHasInvoice, useCreateInvoiceDraftForMultipleOrders} from "../../../Apis/InvoicesApi";
import {IOverviewOrderDto} from "../../../Apis/Models/IOrderDto";
import useToasts from "../../../Hooks/UseToasts";
import React, {useEffect, useState} from "react";
import Loader from "../../../Assets/SVG/Loader";
import {OrderInvoiceDto} from "../../../Apis/Models/OrderInvoiceDto";
import {useInvoiceStatus} from "../Hooks/UseInvoiceStatus";

interface Props {
    visible: boolean;
    toggleVisible: () => void;
    onSuccess?: () => void;
    orders: IOverviewOrderDto[];
}
const CreateOrderInvoiceModal = (props: Props) => {

    const { t } = useTranslation();

    const { showSuccessToast } = useToasts();
    
    const [validationResult, setValidationResult] = useState<OrderInvoiceDto[]>([]);

    const { mutateAsync: validateOrders, isLoading: isValidatingOrders,  } = useCheckIfOrdersHasInvoice();
    const { mutateAsync, isLoading } = useCreateInvoiceDraftForMultipleOrders();
    
    const createInvoiceDraft = async () => {
        let response = await mutateAsync(props.orders.map(x => x.id));

        window.open(response.economicUrl, '_blank');

        showSuccessToast(t('invoiceCreated'));
        
        props.toggleVisible();
        props.onSuccess && props.onSuccess();
    }

    const { getInvoiceStatusLabel } = useInvoiceStatus();

    useEffect(() => {
        if (props.visible) {
            validateOrders(props.orders.map(x => x.id)).then(x => setValidationResult(x));
        }   
    }, [props.orders, setValidationResult, validateOrders, props.visible]);
    
    return (
        <Modal title={t('createInvoice')} visible={props.visible} onCancel={() => props.toggleVisible()}>
            {isValidatingOrders ? (
                <Loader height={40} width={40} />
            ) : (
                <Space size={"large"}>
                    <Trans i18nKey={`createInvoiceForOrders`} values={{ count: props.orders.length }} />

                    {validationResult.length > 0 && (
                        <>
                            <div style={{fontWeight: '700', padding: '10px 0'}}> 
                                {t('ordersAlreadyHasInvoice')}
                            </div>
                            
                            {validationResult.map((item, index) => (
                                <FlexRow style={{paddingBottom: '10px'}} justify={"space-between"}>
                                    <span>
                                        {getInvoiceStatusLabel(item.invoiceStatus)}
                                    </span>
                                    <span>
                                        {t('invoice')} {item.invoiceReference}
                                    </span>
                                    <span>
                                        {t('attachedToOrder')}: {item.orderNumbers.map(x => x).join(', ')}
                                    </span>
                                </FlexRow>
                            ))}
                        </>
                    )}

                    {!isValidatingOrders && (
                        <FlexRow justify={"end"}>
                            <Button type={"secondary"} onClick={createInvoiceDraft} loading={isLoading} text={validationResult.length > 0 ? t('createInvoiceAnyway') : t('createInvoice')} />
                        </FlexRow>
                    )}
                </Space>
            )}
        </Modal>
    )

}

export default CreateOrderInvoiceModal