import { ICustomerDto } from "Apis/Models/ICustomerDto";
import { IProjectDto } from "Apis/Models/IProjectDto";
import {IProductDto} from "./IProductDto";
import {IUserDto} from "./IUserDto";
import {ITagDto} from "./ITagDto";

export interface IMutateOrderDto {
    id: number
    orderTitle: string;
    orderNumber: number;
    createdAt: string;
    comments: string
    
    deliveryAddress: IAddressDto

    projectId?: number;
    customerId: number;

    orderLines: Partial<IOrderLineDto>[];
    deadline: string;
    tags: number[] | ITagDto[];
    version: string;
}

export interface IOverviewOrderDto extends IMutateOrderDto {
    project?: IProjectDto

    customer: ICustomerDto
    currentOrderStatus: OrderStatus;
    orderType: OrderType;
    tags: ITagDto[];
}

export interface IOrderDto extends IOverviewOrderDto {
    orderLines: IOrderLineDto[];
    logs: IOrderLogDto[];
}

export enum OrderStatus {
    Received = 'Received',
    Accepted = 'Accepted',
    Produced = 'Produced',
    Delivered = 'Delivered',
    Finished = 'Finished',
    Cancelled = 'Cancelled'
}

export interface IOrderLineDto {
    id?: number;
    lineIndex: number;
    quantity: number;
    productId: number;
    product: IProductDto;
    outsourcingPartnerId?: number;
    comment: string;
    discountAsAmount: number;
    discountInPercent: number;
    unitSalesPriceInclDiscount: number
}

export interface IAddressDto {
    name: string;
    att: string;
    street: string;
    city: string;
    zip: string;
    email: string
    phone: string
}

export interface IOrderLogDto {
    id: number;
    executedAt: string;
    logType: OrderLogType;
    user?: IUserDto;
    fields: string;
}

export type OrderLogType =
    'OrderCreated' |
    'OrderUpdated' |
    'OrderAcceptedInternal' |
    'OrderAcceptedExternal' |
    'OrderCreatedInClickup' |
    'StockQtyUpdated' |
    'OrderCancelled' |
    'OrderFinished' |
    'ProductionNoteDownloaded'

export type OrderType =
    'Default' |
    'Web'