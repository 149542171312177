import {useTranslation} from "react-i18next";
import PageLayout from "../../Layouts/PageLayout";
import InventoryIcon from "../../Assets/SVG/InventoryIcon";
import {useProducts} from "../../Apis/ProductApi";
import ProductsList from "../../Features/Products/Components/ProductsList";
import Space from "../../Components/Space";
import Button from "../../Components/Button";
import {useNavigate} from "react-router-dom";
import Separator from "../../Components/Separator";
import Pagination from "../../Components/Pagination";
import useQueryParams from "../../Hooks/UseQueryParams";
import ProductListFilter from "../../Features/Products/Components/ProductListFilter";
import {useState} from "react";
import {IProductDto} from "../../Apis/Models/IProductDto";
import useToasts from "../../Hooks/UseToasts";
import FlexRow from "../../Components/FlexRow";
import ChangeProductUnlisted from "../../Features/Products/Components/ChangeProductUnlisted";
import ProductSearchAndReplace from "Features/Products/Components/ProductSearchAndReplace";

const Index = () => {

    const { t } = useTranslation();
    const { showErrorToast } = useToasts();

    const [ filter, setFilter ] = useQueryParams({readyForUse: "true"});

    const [selectedRows, setSelectedRows] = useState<IProductDto[]>([]);
    const [productStatusModalVisible, setProductStatusModalVisible] = useState<boolean>(false);
    const [searchAndReplaceModalVisible, setSearchAndReplaceModalVisible] = useState<boolean>(false);

    const { data: products, isLoading } = useProducts(filter);

    const navigate = useNavigate();

    const handleChangeProductStatus = () => {
        if (selectedRows.length === 0) {
            showErrorToast(t('noProductsSelected'));

            return;
        }

        setProductStatusModalVisible(true);
    }

    const toggleProductStatusModalVisible = () => {
        setProductStatusModalVisible(c => !c);
        setSelectedRows([])
    }

    const handleSearchAndReplace = () => {
        if (selectedRows.length === 0) {
            showErrorToast(t('noProductsSelected'));

            return;
        }

        setSearchAndReplaceModalVisible(true);
    }

    const toggleSearchAndReplaceModalVisible = () => {
        setSearchAndReplaceModalVisible(c => !c);
    }

    return (
        <PageLayout title={t('products')} icon={<InventoryIcon />}  header={(
            <Space direction={"horizontal"}>
                <Button prefix={<InventoryIcon fill={"#FFF"} />} text={t('createNewService')} type={"secondary"} onClick={() => navigate("new/service")} />
                <Button prefix={<InventoryIcon fill={"#FFF"} />} text={t('createNewProduct')} type={"secondary"} onClick={() => navigate("new/product")} />
            </Space>
        )}>
            <div>
                <div>
                    <ProductListFilter filterUpdated={(fields, reset) => setFilter(fields, reset)} currentValue={filter} />
                </div>

                <Separator style={{margin: '30px 0'}} />

                <FlexRow justify={"space-between"}>
                    <Space direction={"horizontal"} size={"small"}>
                        <Button type={"tertiary"} text={t('inventoryItems')} onClick={() => navigate('/products/inventoryItems')} />
                    </Space>
                    <Space direction={"horizontal"} size={"small"}>
                        <Button onClick={handleSearchAndReplace} text={t('searchAndReplace')} />
                        <Button onClick={handleChangeProductStatus} text={t('changeProductStatus')} />
                    </Space>
                </FlexRow>

                <Separator style={{margin: '30px 0'}} />

                <ProductsList products={products?.items} isLoading={isLoading} allowSelect={true} selected={selectedRows} onSelectChange={setSelectedRows} />

                <Pagination paginatedList={products} onPageChange={newPage => setFilter({page: newPage})} />

                <ChangeProductUnlisted visible={productStatusModalVisible} toggleVisible={toggleProductStatusModalVisible} products={selectedRows} onSuccess={() => setSelectedRows([])}  />
                <ProductSearchAndReplace visible={searchAndReplaceModalVisible} toggleVisible={toggleSearchAndReplaceModalVisible} products={selectedRows} onSuccess={() => setSelectedRows([])}  />
            </div>
        </PageLayout>
    )
}

export default Index;