import DotsIcon from "Assets/SVG/DotsIcon";
import List from "Components/List/List";
import {useNavigate} from "react-router-dom";
import FlexRow from "Components/FlexRow";
import {useTranslation} from "react-i18next";
import {IOverviewOrderDto} from "Apis/Models/IOrderDto";
import moment from "moment";
import OrderStatusBullet from "./OrderStatusBullet";
import {useOrderStatus} from "../Hooks/UseOrderStatus";
import {useOrderType} from "../Hooks/UseOrderType";

interface IProps {
    orders?: IOverviewOrderDto[],
    isLoading: boolean,
    allowSelect?: boolean,
    onSelectChange?: (selectedRows: IOverviewOrderDto[]) => void,
    onOrderByChange?: (orderBy: string, orderByDirection: string) => void,
    selected?: IOverviewOrderDto[]
}

const OrdersList = (props: IProps) => {

    const {t} = useTranslation();

    const {orders, isLoading} = props;

    const navigate = useNavigate();

    const {getOrderStatusLabel} = useOrderStatus();
    const {getOrderTypeLabel} = useOrderType();

    return (
        <List<IOverviewOrderDto> allowSelect={props.allowSelect} data={orders} loading={isLoading}
                                 onSelectChange={props.onSelectChange} selected={props.selected} onOrderByChanged={props.onOrderByChange}
                                 defaultOrderBy={"createdAt"} columns={[
            {
                title: "#",
                customRender: (record) => <span>{record.orderNumber}</span>,
                size: "min-content",
                fieldName: 'orderNumber',
                allowOrderBy: true,
            },
            {
                title: t('status'),
                customRender: (record) => <FlexRow><OrderStatusBullet
                    orderStatus={record.currentOrderStatus}/>{getOrderStatusLabel(record.currentOrderStatus)}</FlexRow>,
                size: "min-content"
            },
            {
                title: t('orderTitle'),
                size: "min-content",
                textRender: (record) => record.orderTitle
            },
            {
                title: t('tags'),
                textRender: (record) => record.tags?.map(x => x.name).join(', '),
            },
            {
                title: t('orderType.label'),
                customRender: (record) => <FlexRow>{getOrderTypeLabel(record.orderType)}</FlexRow>,
                size: "min-content"
            },
            {
                title: t('orderDate'),
                textRender: (record) => moment(record.createdAt).format("DD-MM/YYYY HH:mm"),
                fieldName: 'createdAt',
                allowOrderBy: true,
            },
            {
                title: t('deadline'),
                textRender: (record) => moment(record.deadline).format("DD-MM/YYYY"),
                fieldName: 'deadline',
                allowOrderBy: true,
            },
            {
                title: t('customer'),
                textRender: (record) => record.customer.name,
                fieldName: 'customer',
                allowOrderBy: true,
            },
            {
                title: t('project'),
                textRender: (record) => record.project?.name ?? '',
                fieldName: 'project',
                allowOrderBy: true,
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => <FlexRow className={"h-100 clickable"} justify={"center"}
                                                   onClick={() => navigate(`/orders/${record.id}`)}><DotsIcon/></FlexRow>
            }
        ]} keyExtractor={(record) => record.id.toString()}/>
    )
}

export default OrdersList;
