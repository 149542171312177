import Button from "Components/Button";
import FlexRow from "Components/FlexRow";
import Modal from "Components/Modal";
import Space from "Components/Space";
import {Trans, useTranslation} from "react-i18next";
import useToasts from "../../../Hooks/UseToasts";
import React from "react";
import {IProductDto} from "../../../Apis/Models/IProductDto";
import {useChangeProductStatusForMultipleProducts} from "../../../Apis/ProductApi";

interface Props {
    visible: boolean;
    toggleVisible: () => void;
    onSuccess?: () => void;
    products: IProductDto[];
}
const ChangeProductUnlisted = (props: Props) => {

    const { t } = useTranslation();

    const { showSuccessToast } = useToasts();

    const { mutateAsync, isLoading } = useChangeProductStatusForMultipleProducts();

    const changeStatus = async () => {
        await mutateAsync({
            productIds: props.products.map(x => x.id),
            unlisted: !props.products[0].unlisted
        });

        showSuccessToast(t('statusChanged'));
        
        props.toggleVisible();
        props.onSuccess && props.onSuccess();
    }

    return (
        <Modal title={t('changeProductStatus')} visible={props.visible} onCancel={() => props.toggleVisible()} modalStyle={{overflowY: 'visible'}}>
            <Space size={"large"}>
                {props.products.length > 0 && (
                    <>
                        {props.products.filter(x => x.unlisted === props.products[0].unlisted).length === props.products.length ? (
                            <>
                                <Trans i18nKey={`changeMultipleProductsUnlisted`} values={{ newStatus: props.products[0].unlisted ? t('relist') : t('unlist'),  count: props.products.length }} />

                                <FlexRow justify={"end"}>
                                    <Button type={"secondary"} onClick={changeStatus} loading={isLoading} text={props.products[0].unlisted ? t('relist') : t('unlist')}/>
                                </FlexRow>
                            </>
                            ) : (
                            <div>
                                {t('notAllProductsHasSameUnlistedStatus')}
                            </div>
                        )}

                    </>
                )}
            </Space>
        </Modal>
    )
}

export default ChangeProductUnlisted