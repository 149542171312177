import {ReactElement, useCallback, useMemo, useState} from "react";
import styles from './List.module.scss';
import FlexRow from "Components/FlexRow";
import Loader from "Assets/SVG/Loader";
import {IFileInformationDto} from "Apis/Models/IFileDto";
import React from "react";
import CheckmarkIcon from "../../Assets/SVG/CheckmarkIcon";
import UncheckedIcon from "../../Assets/SVG/UncheckedIcon";
import SmallCaret from "../../Assets/SVG/SmallCaret";
import {useTranslation} from "react-i18next";

export interface IColumnProps<T> {
    icon?: ReactElement
    customRender?: (record: T) => ReactElement,
    textRender?: (record: T) => string,
    title?: string | any;
    size?: 'auto' | 'max-content' | 'min-content' | string,
    allowOrderBy?: boolean;
    fieldName?: string;
}

interface IProps<T> {
    loading?: boolean,
    data?: T[],
    columns: IColumnProps<T>[],
    emptyText?: string,
    keyExtractor: (record: T) => string,
    imageExtractor?: (record: T) => IFileInformationDto[] | null | undefined,
    allowSelect?: boolean,
    onSelectChange?: (selectedRows: T[]) => void,
    onOrderByChanged?: (orderBy: string, orderByDirection: string) => void,
    defaultOrderBy?: string,
    selected?: T[] | undefined
}

const List = <T, >(props: IProps<T>) => {

    const {t} = useTranslation();

    const [orderByField, setOrderByField] = useState<string>(props.defaultOrderBy ?? '')
    const [orderByDirection, setOrderByDirection] = useState<'ASCENDING' | 'DESCENDING'>('ASCENDING');

    const {loading, allowSelect, onSelectChange, data, columns, emptyText, keyExtractor, imageExtractor, selected } = props;

    const setSort = (column: IColumnProps<T>) => {
        if (!column.allowOrderBy || !column.fieldName) {
            return;
        }

        let newDirection = orderByDirection
        let newField = column.fieldName;

        if (orderByField === column.fieldName) {
            newDirection = orderByDirection === 'ASCENDING' ? 'DESCENDING' : 'ASCENDING'
        } else {
            newDirection = 'ASCENDING'
        }

        setOrderByField(newField)
        setOrderByDirection(newDirection)

        props.onOrderByChanged?.(newField, newDirection)
    }

    const getGridCss = () => {
        return columns.map(x => x.size ?? '1fr').join(' ')
    }

    const isSelected = useCallback((record: T) => {
        return selected?.map(x => keyExtractor(x)).includes(keyExtractor(record));
    }, [keyExtractor, selected]);

    const toggleSelected = useCallback((record: T) => {
        let clone = [...selected ?? []];


        if (isSelected(record)) {
            clone = clone.filter(x => keyExtractor(x) !== keyExtractor(record))
        } else {
            clone = [...clone, record]
        }

        onSelectChange?.(clone)
    }, [isSelected, keyExtractor, onSelectChange, selected])

    const rows = useMemo(() => {
        return data?.map((record: T) => (
            <div className={styles.row} key={keyExtractor(record)}>
                {columns.map((column, index) => (
                    <React.Fragment key={index}>
                        {index === 0 && !!imageExtractor ? (
                            <div className={`flex align-center w-100 ${styles.column} ${styles.image}`}>
                                <div className={styles.rowImage}>
                                    {imageExtractor(record)?.map((x, index) =>
                                        <img className={styles.img} src={x?.url} alt={x?.fileName} key={index}/>
                                    )}
                                </div>
                                <div className={`${index !== 0 && styles.separator} flex column justify-center`}>
                                    {column.customRender ? column.customRender(record) : column.textRender && column.textRender(record)}
                                </div>
                                {index === 0 && allowSelect && (
                                    <div onClick={() => toggleSelected(record)}
                                         className={`${styles.selector} ${isSelected(record) ? styles.visible : ''}`}>{isSelected(record) ?
                                        <CheckmarkIcon/> : <UncheckedIcon/>} </div>
                                )}
                            </div>
                        ) : (
                            <div className={`flex column justify-center w-100 ${styles.column}`}>
                                <div className={`${index !== 0 && styles.separator} flex column justify-center`}>
                                    {column.customRender ? column.customRender(record) : column.textRender && column.textRender(record)}
                                </div>
                                {index === 0 && allowSelect && (
                                    <div onClick={() => toggleSelected(record)}
                                         className={`${styles.selector} ${isSelected(record) ? styles.visible : ''}`}>{isSelected(record) ?
                                        <CheckmarkIcon/> : <UncheckedIcon/>} </div>
                                )}
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        ))
    }, [columns, data, keyExtractor, imageExtractor, allowSelect, toggleSelected, isSelected])

    if (loading) {
        return (
            <FlexRow justify={"center"} align={"center"} className={"h-100"}>
                <Loader fill={"#e91d25"} width={100} height={100}/>
            </FlexRow>
        )
    }

    if (data?.length === 0) {
        return (
            <FlexRow justify={"center"} align={"center"} className={"h-100"}>
                {emptyText ?? t('noResultsFound')}
            </FlexRow>
        )
    }

    return (
        <div className={styles.container} style={{gridTemplateColumns: getGridCss()}}>
            {columns.map((column, index) => (
                <div className={`${styles.title} ${column.allowOrderBy && 'clickable'}`} key={index}
                     onClick={() => setSort(column)}>
                    {column.title} {orderByField === column.fieldName && (<SmallCaret direction={orderByDirection}/>)}
                </div>
            ))}
            {rows}
        </div>
    )
}

export default List
